import Alpine from 'alpinejs'
import './tooltip.js'
import ui from '@alpinejs/ui'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
import intersect from '@alpinejs/intersect' // needed for number_counter.js
import timer from './components/timer.js'
import number_counter from './components/number-counter.js'
import scroll_to_top from './components/scroll-to-top.js'

window.Alpine = Alpine

Alpine.plugin(focus)
Alpine.plugin(ui)
Alpine.plugin(collapse)
Alpine.plugin(intersect)

Alpine.data('number_counter', number_counter)
Alpine.data('timer', timer)
Alpine.data('scrollToTop', scroll_to_top)

Alpine.start()
