export default () => ({
    isVisible: true,
    handleScroll: function () {
        this.isVisible = window.scrollY > 400
        console.log(window.scrollY)
        // window.pageYOffset > window.scrollY > 100; ? true : false
    },
    handleClick: function () {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    },
})
